<template>
  <div>
    <!-- pc -->
    <div class="header-pc">
      <div class="header-container">
        <div class="flex header" style="height:100%;width:1152px;margin:auto">
          <div
            class="unselect size-24 weight-700 position-relative flex-align"
            style="margin-right: 16px;"
            @click="clickLogo"
          >
            <img src="/static/img/Platformfy.svg" style="vertical-align: middle" alt="플랫폼파이 로고" />
          </div>

          <!--<img src="/static/img/header_logo.svg" alt="" class="unselect"
               style="width:173px;height:68.75px;margin-right:40px;margin-top:6px" @click="clickLogo">-->
          <!-- menu -->
          <div class="flex-between" style="flex:1">
            <div class="flex" style="width:100%;height:100%">
              <div
                v-for="(menu, idx) in menus"
                :key="'menu-' + idx"
                class="table-cell subtitle5 selected-menu unselect"
                @mouseover="selectedMenu = menu"
                :style="cellStyle(menu.name)"
              >
                <div class="menu-name" @click="clickMenu(menu)">
                  {{ menu.name }}
                  <div class="indicator"></div>
                </div>

                <!-- 2depth menu -->
                <div class="selected-menu-wrapper" v-if="selectedMenu && selectedMenu.child">
                  <template v-for="(item, idx) in selectedMenu.child">
                    <template v-if="item.child">
                      <div class="menu-item-1" :key="`item-${idx}`">
                        <div class="menu-item-name-1 body4-bold">{{ item.name }}</div>
                        <div
                          v-for="(child_item, child_idx) in item.child"
                          :key="`child_item-${child_idx}`"
                          class="menu-item-2 body4"
                          :style="selectedChildStyle(child_item)"
                          @click="clickMenu(child_item)"
                        >
                          {{ child_item.name }}
                        </div>
                      </div>
                    </template>
                    <div v-else :key="`item-${idx}`" class="menu-item-2 body4" @click="clickMenu(item)">
                      {{ item.name }}
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <!-- right menu -->
            <div class="flex-end" style="width:100%">
              <!--<a href="https://pfa.launchpack.co.kr/prime" target="_blank">-->
              <a href="https://launchpack.co.kr/prime" target="_blank">
                <div class="table-cell unselect" style="margin-right:24px">
                  <div class="flex-align menu-name primary" style="padding:16px 0">
                    <div class="body4 primary" style="margin-right:4px">맞춤제작</div>
                    <svg-icon :width="16" :height="16" icon="u_arrow-up-right" color="primary"></svg-icon>
                  </div>
                </div>
              </a>
              <div v-if="isLogin" class="user-region unselect">
                <img class="account_outline" src="/static/icon/avata_default.png" alt="" />
                <span>{{ userName }}</span>
                <img class="arrow_down" src="/static/icon/u_angle-down.svg" />

                <div class="lp-mypage-menu-bg">
                  <div class="lp-mypage-menu">
                    <div class="unselect" style="padding:12px" @click="clickMyService">내 서비스</div>
                    <div class="unselect" style="padding:12px;border-top:1px solid #eee;" @click="clickCoupon">
                      내 쿠폰
                    </div>
                    <div class="unselect" style="padding:12px;border-top:1px solid #eee;" @click="clickSite">
                      내 정보 수정
                    </div>
                    <div class="unselect" style="padding:12px;border-top:1px solid #eee;" @click="clickLogout">
                      로그아웃
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex" style="height:100%;gap:24px" v-else>
                <div @click="clickLogin" class="table-cell unselect">
                  <div class="menu-name">로그인</div>
                </div>
                <!-- <div @click="$router.push('/signup')" class="table-cell unselect">
                  <div class="menu-name">회원가입</div>
                </div> -->
              </div>
              <a
                v-if="!isLogin"
                class="button is-primary flex-center margin-left-24 body4-medium"
                style="width:150px;height:40px"
                href="https://walla.my/survey/gZBAG9imMdaiyop6z6It"
                target="_blank"
              >
                무료로 시작하기
              </a>
              <button
                v-else
                class="button is-primary-light margin-left-24 body4-medium"
                style="width:150px;height:40px"
                @click="routerPush('/mypage/my_service')"
              >
                내 서비스
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- mobile -->
    <div class="header-mobile" v-if="visibleHeaderMobile">
      <div class="header-container">
        <div class="flex-between" style="height: 100%;padding:0 4px">
          <div class="unselect size-22 weight-700 padding-12 position-relative" @click="clickLogo">
            <img src="/static/img/Platformfy_m.svg" style="vertical-align: middle" alt="플랫폼파이 로고" />
          </div>
          <div class="flex-align">
            <a
              class="flex-center button is-primary-light body4-medium margin-right-12"
              style="width:124px;height:32px"
              href="https://walla.my/survey/gZBAG9imMdaiyop6z6It"
              target="_blank"
            >
              무료로 시작하기
            </a>
            <img class="unselect padding-12" src="/static/icon/fi_menu.svg" @click="$refs.pageDrawerLeft.open()" />
          </div>
        </div>
      </div>
    </div>

    <!-- 드로워 (모바일) -->
    <drawer class="header-mobile" direction="right" :exist="true" ref="pageDrawerLeft" @close="onCloseDrawerLeft">
      <div class="drawer-container">
        <!-- header -->
        <div class="flex-between drawer-header">
          <img class="padding-12" src="/static/icon/fi_close.svg" @click="$refs.pageDrawerLeft.close()" />
          <div class="flex-align">
            <div v-if="isLogin" class="user-region unselect">
              <img class="account_outline" src="/static/icon/avata_default.png" alt="" />
              <span class="body4-medium sub2">{{ userName }}</span>
              <span class="margin-left-12 margin-right-12">|</span>
              <div class="body4 sub2 margin-right-16" @click="clickLogout">로그아웃</div>
            </div>
            <div @click="$router.push('signin')" class="table-cell unselect" v-else>
              <div class="body4 sub2 margin-right-16" @click="clickLogin">로그인</div>
            </div>
          </div>
        </div>
        <!-- menu mobile -->
        <div class="drawer-content">
          <div
            v-for="(menu, idx) in menusMobile"
            :key="'menu-' + idx"
            class="table-cell selected-menu unselect"
            @click="clickFirstMenu(menu)"
          >
            <div class="flex-between padding-16" style="border-bottom: 1px solid #eeeeee">
              <div class="subtitle5">{{ menu.name }}</div>
              <img
                :src="`/static/icon/fi_chevron-${selectedMenu && menu.path === selectedMenu.path ? 'up' : 'down'}.svg`"
                class="svg-sub4"
                v-if="menu.child"
              />
            </div>
            <div class="selected-menu-wrapper" v-if="selectedMenu && menu.path === selectedMenu.path">
              <template v-for="(item, idx) in selectedMenu.child">
                <template v-if="item.child">
                  <div class="menu-item-1" :key="`item-${idx}`">
                    <div class="subtitle7">{{ item.name }}</div>
                    <div
                      class="menu-item-2"
                      v-for="(child_item, child_idx) in item.child"
                      :key="`child_item-${child_idx}`"
                      @click="clickMenu(child_item)"
                    >
                      <div class="body4 sub3" :class="{ 'margin-top-12': !(item.name === '' && child_idx === 0) }">
                        {{ child_item.name }}
                      </div>
                    </div>

                    <div
                      class="lp-divider-gray1 margin-top-24 margin-bottom-24"
                      v-if="idx < selectedMenu.child.length - 1"
                    ></div>
                  </div>
                </template>
                <div v-else class="menu-item-2" :key="`item-${idx}`" @click="clickMenu(item)">
                  <div class="body4 sub3" :class="{ 'margin-top-16': idx !== 0 }">{{ item.name }}</div>
                </div>
              </template>
            </div>
          </div>
          <div class="table-cell selected-menu unselect">
            <!--<a href="https://pfa.launchpack.co.kr/prime" target="_blank">-->
            <a href="https://launchpack.co.kr/prime" target="_blank">
              <div class="subtitle5 primary flex-align padding-16">
                맞춤제작 <svg-icon icon="u_arrow-up-right" color="primary"></svg-icon>
              </div>
            </a>
          </div>
        </div>
      </div>
    </drawer>

    <!-- 로딩 -->
    <div class="lp-loading" v-if="loadingStatus">
      <div class="flex" style="justify-content: center;">
        <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
      </div>
    </div>
    <div style="min-height:calc(100vh - 74px)">
      <transition name="fade">
        <keep-alive :include="alivePages">
          <router-view></router-view>
        </keep-alive>
      </transition>
    </div>

    <!-- 푸터 -->
    <lp-footer v-if="visibleFooter"></lp-footer>

    <lp-sign-modal ref="signModal"></lp-sign-modal>

    <!-- 주문제작 신청 -->
    <sweet-modal ref="reqModal">
      <lp-service-inquiry-modal @closeModal="$refs.reqModal.close()"></lp-service-inquiry-modal>
    </sweet-modal>

    <!-- Top 버튼 -->
    <transition name="fade">
      <div @click="clickTop" class="btn-top unselect" v-show="vTop">
        <i class="material-icons" style="font-size:30px">arrow_drop_up</i><br />TOP
      </div>
    </transition>
  </div>
</template>

<script>
import LpFooter from './component/LpFooter';
import LpSignModal from './modal/LpSignModal';
import { SweetModal } from 'sweet-modal-vue';
import LpServiceInquiryModal from './modal/LpServiceInquiryModal';
import Drawer from './component/Drawer';
import SvgIcon from './component/SvgIcon';
export default {
  name: 'Main',
  components: {
    SvgIcon,
    Drawer,
    LpServiceInquiryModal,
    SweetModal,
    LpSignModal,
    LpFooter,
  },

  created() {
    this.setAuthHeader();
    this.$store.commit('setLoading', false);
    this.$root.$on('login', () => {
      this.$refs.signModal.open();
    });
    window.addEventListener('scroll', () => {
      let top = document.getElementsByTagName('html')[0].scrollTop;
      this.vTop = top > 300 && this.visibleTop;
    });
  },

  watch: {
    '$store.getters.openLogin'(n) {
      if (n) {
        this.$refs.signModal.open();
        // this.$router.replace();
        this.$store.commit('setOpenLogin', false);
      }
    },
  },

  computed: {
    // TOP 버튼 노출 조건
    visibleTop() {
      let exclude = ['/service_inquiry_detail', '/design_inquiry_detail', '/service_create'];

      return exclude.indexOf(this.$route.path) === -1;
    },
    //모바일 헤더 노출 조건
    visibleHeaderMobile() {
      let exclude = ['/service_inquiry_detail', '/design_inquiry_detail'];

      return exclude.indexOf(this.$route.path) === -1;
    },
    //푸터 노출 조건
    visibleFooter() {
      let exclude = [
        '/inquiry_reg',
        'inquiry_reg',
        '/service_inquiry_detail',
        '/design_inquiry_detail',
        '/service_create',
      ];

      return exclude.indexOf(this.$route.path) === -1;
    },
    menusMobile() {
      if (this.isLogin) {
        let menu = [
          {
            name: '마이페이지',
            path: 'mypage',
            child: [
              {
                name: '',
                child: [
                  { name: '내 정보 수정', path: '/mypage/profile' },
                  { name: '내 서비스', path: '/mypage/my_service' },
                  { name: '내 쿠폰', path: '/mypage/my_coupon' },
                ],
              },
            ],
          },
        ];
        return this.menus.concat(menu);
      } else {
        return this.menus;
      }
    },
    loadingStatus() {
      return this.$store.getters.loading;
    },
    userName() {
      if (this.$store.getters.user.user_id > 0) return this.$store.getters.user.name;
      else return '';
    },
  },
  data() {
    return {
      alivePages: ['Home', 'Store', 'Market'],
      vTop: false,
      selectedMenu: undefined,
      menus: [
        {
          name: '플랫폼 파이',
          path: '/platformfy_info',
        },
        {
          name: '테마',
          path: '/theme_market',
        },
        {
          name: '플러그인',
          path: '/market/list',
        },
        {
          name: '블로그',
          path: '/post',
        },
        {
          name: '고객센터',
          path: '/care_service/intro',
          child: [
            {
              name: '케어서비스',
              path: '/care_service/intro',
            },
            {
              name: '공지사항',
              path: '/notice',
            },
            {
              name: 'FAQ',
              path: '/faq',
            },
            {
              name: '1:1문의',
              path: '/inquiry',
            },
          ],
        },
      ],
      popupMarket: undefined,
    };
  },
  methods: {
    onMessageMarket(e) {
      if (e.data.close) {
        this.popupMarket.close();
        this.removeMessageEvent();
        if (e.data.redirectUrl) {
          setTimeout(() => {
            window.open(e.data.redirectUrl);
          }, 300);
        }
      }
    },
    addMessageEvent() {
      window.addEventListener('message', this.onMessageMarket);
    },
    removeMessageEvent() {
      window.removeEventListener('message', this.onMessageMarket);
    },
    clickLogo() {
      this.$store.commit('setTrial', false);
      if (this.$route.path === '/home') {
        this.$router.go(this.$route.currentRoute);
      } else {
        this.routerPush('/home');
      }
    },
    clickTop() {
      document.getElementsByTagName('html')[0].scrollTop = 0;
    },
    isCurrentMenu(menu) {
      return this.$route.path.indexOf(menu.path) > -1;
    },
    selectedMenuStyle(menu) {
      let selected = false;
      if (menu.path === this.$route.path) {
        selected = true;
      } else if (menu.child) {
        menu.child.forEach((c) => {
          let index = c.child.findIndex((item) => {
            return item.path === this.$route.path;
          });
          if (index > -1) {
            selected = true;
          }
        });
      }
      let deco = {
        color: '#242428',
        borderBottom: '3px solid transparent',
      };
      if (selected) {
        deco.color = '#ff6600';
        deco.borderBottom = '3px solid #ff6600';
      }
      return deco;
    },
    selectedChildStyle(item) {
      return item.path === this.$route.path ? { color: '#FF6600' } : '';
    },
    cellStyle(text) {
      let val = 0;
      if (text.length < 3) val = 62;
      else if (text.length < 5) val = 80;
      else if (text.length < 7) val = 110;
      else if (text.length < 9) val = 120;
      else if (text.length < 11) val = 140;
      else if (text.length < 13) val = 160;
      return { width: `${val}px` };
    },
    clickFirstMenu(menu) {
      if (!menu.child) {
        this.clickMenu(menu);
      } else if (this.selectedMenu && this.selectedMenu.path === menu.path) {
        this.selectedMenu = undefined;
      } else {
        this.selectedMenu = menu;
      }
    },
    // 메뉴 클릭
    clickMenu(menu) {
      this.$store.commit('setTrial', false);
      if (menu.path === '/market/list') {
        if (this.isMobile) {
          this.routerPush('/appmarket_info');
        } else {
          /*const a = document.createElement('a');
            a.href = '/market/list';
            a.target = '_blank';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.parentNode.removeChild(a);*/
          let url = '/market/list';
          let width = 1231;
          let height = 760;
          /*if(window.innerHeight < height) {
              height = window.innerHeight - 80;
            }
            if(window.innerWidth < width) {
              width = window.innerWidth - 200;
            }*/
          this.popupMarket = window.open(
            url,
            'appMarket',
            `width=${width}, height=${height}, top=0, left=0, menubar=no, toolbar=no, titlebar=yes`
          );
          this.addMessageEvent();
        }
      } else if (menu.path === '/quickbuilder' && this.isMobile) {
        this.toast('PC에서만 제공되는 서비스입니다');
        return;
      } else if (menu.path === '/inquiry' && !this.isLogin) {
        this.routerPush('/inquiry_reg');
      } else if (menu.path && this.$route.fullPath !== menu.path) {
        this.routerPush(menu.path);
      }
      this.selectedMenu = undefined;
      this.closeDrawerLeft();

      /*  if (menu.path === 'req') {
          this.setGa('상단메뉴 (주문제작 신청)', '클릭', '주문제작 신청', 1);
          this.$refs.reqModal.open();
        } else {
          this.setGa(`상단 메뉴 (${menu.name})`, '클릭', menu.path, 1);
          this.$router.push('/' + menu.path);
        }*/
    },
    // 사이트 바로가기
    clickSite() {
      this.$router.push('/mypage/profile');
      this.closeDrawerLeft();
    },
    // 내 서비스 페이지 바로가기
    clickMyService() {
      this.$router.push('/mypage/my_service');
      this.closeDrawerLeft();
    },
    clickCoupon() {
      this.$router.push('/mypage/my_coupon');
      this.closeDrawerLeft();
    },
    onCloseDrawerLeft() {
      this.page_drawer_left = undefined;
      //this.$store.commit('removePopstate');
    },
    closeDrawerLeft() {
      if (window.innerWidth <= 1024) {
        this.$refs.pageDrawerLeft.close();
      }
    },
    clickLogin() {
      this.$store.commit('setTrial', false);
      this.routerPush('/signin');
      this.closeDrawerLeft();
    },
    // 로그아웃
    clickLogout() {
      this.$axios.post('/auth/user/logout?token=' + this.$store.getters.user.token).then((res) => {
        if (res.status === 200) {
          this.toast('로그아웃 되었습니다.');
          this.logout();
          this.$router.replace('/home');
          this.closeDrawerLeft();
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
$pc-header-height = 74px

.beta
  position absolute
  right -26px
  top -3px
  color #ff6600
  font-size 10px


.lp-mypage-menu-bg
  display none

.user-region
  display flex
  align-items center
  justify-content center

.user-region:hover .lp-mypage-menu-bg
  position fixed
  z-index 100
  display block
  top 44px
  padding-top 12px
  transform translateX(-25%)

.lp-mypage-menu
  background white
  box-shadow 0px 0px 30px 0px rgba(0, 0, 0, 0.12)
  border-radius 8px
  width 160px

.lp-mypage:hover .lp-mypage-menu-bg
  display block

.arrow_down
  width 18px
  height 18px
  margin-left 4px

.account_outline
  width 24px
  height 24px
  margin-right 8px

.lp-loading
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  z-index 10000
  background rgba(0, 0, 0, 0.3)
  display flex
  justify-content center

.lp-loading i
  position fixed
  top 50%

.tri
  width 0
  height 0
  border-bottom 8px solid white
  border-right 8px solid transparent
  border-left 8px solid transparent
  transform translateX(-50%)
  /*-ms-border-bottom: 8px solid white;*/
  /*-ms-border-right: 8px solid transparent;*/
  /*-ms-border-left:8px solid transparent;*/
  -ms-transform translateX(-50%)

.btn-top
  font-size 12px
  position fixed
  bottom 160px
  right 46px
  z-index 1000
  background-color white
  box-shadow 3px 3px 6px 0 rgba(0, 0, 0, 0.11)
  width 50px
  height 50px
  border-radius 50%
  line-height 0
  text-align center

.drawer-container
  display flex
  flex-direction column
  width 100%
  height 100vh
  background white

.drawer-header
  height 60px
  border-bottom 1px solid $gray2
.drawer-content
  flex 1
  overflow-y auto

.header-pc
  .header-container
    background-color #ffffff
    align-items center
    height $pc-header-height
    position fixed
    top 0
    left 0
    width 100%
    z-index 10
    border-bottom 1px solid $gray1

  .selected-menu-wrapper
    background-color white
    position fixed
    top ($pc-header-height - 1px)
    padding 4px 24px 20px 24px
    text-align left
    box-shadow 3px 10px 20px rgba(0, 0, 0, 0.07)
    border-radius 8px
    border-top-left-radius 0
    border-top 1px solid $gray1

  .menu-item-name-1
    padding-top 16px
    color $sub4

  .menu-item-2
    color $sub2
    margin-top 12px
  .menu-item-2:hover
    color $primary

  .menu-item-1
    padding-bottom 16px
    border-bottom 1px solid $gray2

  .menu-item-1:last-child
    border-bottom 0

  .selected-menu-wrapper
    display none

  .selected-menu:hover .selected-menu-wrapper
    display block

  .table-cell
    color $sub
    text-align center
    height 100%
  .menu-name:hover
    color $primary !important
    position relative
    .indicator
      width 100%
      height 3px
      background $primary
      position absolute
      bottom 11px
  .table-cell > .menu-name
    line-height 73px

.header-mobile
  .header-container
    background-color #ffffff
    align-items center
    height 60px
    position fixed
    top 0
    left 0
    width 100%
    z-index 10
    justify-content center
    border-bottom 1px solid $gray1

  .table-cell
    /*width 100%*/
    // border-bottom 1px solid $gray2

  .selected-menu-wrapper
    padding 24px
    background-color $gray4

.account-outline-wrapper
  position relative

.account-outline-wrapper img
  position absolute
  left 50%
  top 50%
  transform translate(-50%, -50%)
  font-size 13px

.account-outline-circle
  border-radius 50%
  padding 12px
  background-color $gray2

.fade-enter-active {
  transition: opacity .3s;
}
.fade-leave-active {
  transition: opacity .2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media (max-width:1024px)
  .btn-top
    left 18px
    bottom 16px
  .beta
    top 16px
    right -14px
</style>
